import React, { ComponentProps, useCallback, useState } from "react";
import useEmojiReactions from "modules/emoji/Reactions/hooks/useEmojiReactions";
import { Lottie } from "@remo-co/ui-core/src/components/Lottie";
import { AnimationDirection } from "@remo-co/ui-core/src/types";
import CountdownAnimation from "../assets/countdown.json";
import { EmojiReactions } from "../Reactions/types";
import { useStyles } from "./styles";

interface IReactedEmoji {
  type: EmojiReactions;
  animationData: ComponentProps<typeof Lottie>["animationData"];
  imageType: string;
}

const COUNTDOWN = 5_000;

const ReactedEmoji = ({ type, imageType, animationData }: IReactedEmoji) => {
  const { addReaction } = useEmojiReactions();
  const [isHovering, setIsHovering] = useState(false);
  const classes = useStyles();

  const [isCountdown, setIsCountdown] = useState(false);
  const handleMouseEnter = useCallback(() => {
    setIsHovering(true);
  }, [setIsHovering]);

  const handleMouseLeave = useCallback(() => {
    setIsHovering(false);
  }, [setIsHovering]);

  const startCountdown = useCallback(() => {
    setIsCountdown(true);

    setTimeout(() => {
      setIsCountdown(false);
    }, COUNTDOWN);
  }, []);

  const handleClick = useCallback(() => {
    if (isCountdown) return;

    startCountdown();
    addReaction(type);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addReaction, startCountdown]);

  return (
    <div className={classes.buttonContainer}>
      <button
        type="button"
        className={classes.emojiButton}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleClick}
      >
        <div className={classes.animation}>
          {isCountdown && (
            <Lottie
              animationData={CountdownAnimation}
              speedMultiplier={0.4}
              direction={AnimationDirection.REVERSE}
            />
          )}
        </div>
        {isHovering ? (
          <Lottie animationData={animationData} />
        ) : (
          <img
            className={classes.emojiImage}
            src={imageType}
            alt={`${type} reaction`}
          />
        )}
      </button>
    </div>
  );
};

export default React.memo(ReactedEmoji);
