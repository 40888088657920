import { EmojiReactions } from "modules/emoji/Reactions/types";
import {
  FLOATING_REACTION_IMAGE_SIZE,
  REACTIONS_CONTAINER_ID,
} from "../constants";
import { emojiTypes } from "../EmojiReaction";

export function playFloatingReaction(reactionType: EmojiReactions) {
  const container = document.getElementById(REACTIONS_CONTAINER_ID);
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  const reaction = createFloatingReaction(reactionType, () => {
    reaction.parentElement?.removeChild(reaction);
  });

  requestAnimationFrame(() => {
    container?.appendChild(reaction);
  });
}

export function createFloatingReaction(
  type: EmojiReactions,
  onAnimationEnd: () => void,
  animationNumber = Math.floor(1 + Math.random() * 29), // Allow injection in tests
) {
  const horizontalAnimationContainer = document.createElement("div");

  horizontalAnimationContainer.className = `emoji-animation-horizontal emoji-animation-horizontal-${animationNumber}`;

  const verticalAnimationContainer = document.createElement("div");

  verticalAnimationContainer.className = `emoji-reaction emoji-animation-vertical-${animationNumber} emoji-${type}`;
  verticalAnimationContainer.addEventListener("animationend", onAnimationEnd);

  const image = document.createElement("img");

  image.className = "emoji-image";
  image.width = FLOATING_REACTION_IMAGE_SIZE;
  image.height = FLOATING_REACTION_IMAGE_SIZE;
  image.src = emojiTypes[type];

  horizontalAnimationContainer.appendChild(verticalAnimationContainer);
  verticalAnimationContainer.appendChild(image);

  return horizontalAnimationContainer;
}
