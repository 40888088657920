import Heart from "../assets/emoji_heart.svg";
import Confetti from "../assets/emoji_confetti.svg";
import Laugh from "../assets/emoji_laugh.svg";
import Clap from "../assets/emoji_clap.svg";
import Like from "../assets/emoji_thumbs.svg";
import Confused from "../assets/emoji_monocle.svg";
// TODO: This needs to be solved in a future issue.
import "./EmojiReaction.scss";

export const emojiTypes = {
  clap: Clap,
  like: Like,
  confused: Confused,
  heart: Heart,
  laugh: Laugh,
  confetti: Confetti,
};
