import { ConversationEmoji as ConversationEmojiType } from "modules/emoji/redux";
import { emojiTypes } from "modules/emoji/Reactions/EmojiReaction";
import { useStyles } from "./styles";

interface Props {
  avatarEmoji?: boolean;
  emoji?: ConversationEmojiType;
}

export const TileEmoji = ({ avatarEmoji = false, emoji }: Props) => {
  const classes = useStyles();

  if (!emoji) {
    return null;
  }

  return (
    <div data-testid="tile-emoji">
      <img
        className={avatarEmoji ? classes.avatarEmojiImg : classes.tileEmojiImg}
        src={emojiTypes[emoji.reactionType]}
        alt={`${emoji.reactionType} reaction`}
      />
    </div>
  );
};
