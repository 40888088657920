import { createSelector } from "@reduxjs/toolkit";
import { selectUserId } from "modules/auth/redux/selectors";
import { RootState } from "store";

const selectEmoji = (state: RootState) => state.emoji;

const selectConversationEmoji = createSelector(
  selectEmoji,
  (substate) => substate.conversation,
);

const selectUserConversationEmoji = (userId?: string) =>
  createSelector([selectConversationEmoji], (conversationEmoji) =>
    userId ? conversationEmoji[userId] : undefined,
  );

const selectCurrentUserConversationEmoji = createSelector(
  selectConversationEmoji,
  selectUserId,
  (conversationEmoji, userId) =>
    userId ? conversationEmoji[userId] : undefined,
);

export {
  selectConversationEmoji,
  selectUserConversationEmoji,
  selectCurrentUserConversationEmoji,
};
