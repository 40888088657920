import { useCallback, useState } from "react";
import useEmojiReactions from "modules/emoji/Reactions/hooks/useEmojiReactions";
import { EmojiReactions } from "modules/emoji/Reactions/types";
import { Lottie } from "@remo-co/ui-core/src/components/Lottie";
import { AnimationDirection } from "@remo-co/ui-core/src/types";
import throttle from "modules/throttle";
import { useStyles } from "./styles";
import { emojiTypes } from "../Reactions/EmojiReaction";
import CountDownAnimation from "../assets/countdown.json";

interface Props {
  type: string;
}

const COUNTDOWN = 5000;

export const ConversationEmoji = ({ type }: Props): JSX.Element => {
  const { addReaction } = useEmojiReactions();
  const classes = useStyles();

  const [isCountdown, setIsCountdown] = useState(false);

  const startCountdown = throttle(
    () => {
      setIsCountdown(false);
    },
    COUNTDOWN,
    { leading: false },
  );

  const handleClick = useCallback(() => {
    if (isCountdown) return;
    setIsCountdown(true);
    addReaction(type as EmojiReactions);
    startCountdown();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addReaction, startCountdown, isCountdown]);

  return (
    <div className={classes.container}>
      <button
        type="button"
        name="emoji-button"
        className={classes.emojiButton}
        disabled={isCountdown}
        onClick={handleClick}
      >
        {isCountdown && (
          <div className={classes.animation}>
            <Lottie
              animationData={CountDownAnimation}
              speedMultiplier={0.4}
              direction={AnimationDirection.REVERSE}
            />
          </div>
        )}

        <img
          className={classes.emojiImage}
          src={emojiTypes[type as EmojiReactions]}
          alt={`${type} reaction`}
        />
      </button>
    </div>
  );
};
