import { useSelector } from "react-redux";
import { Container } from "@remo-co/ui-core/src/components/Container";
import ReactedEmoji from "../ReactedEmoji/ReactedEmoji";
import { getAnimationData } from "../Reactions/utils";
import Clap from "../assets/emoji_clap.svg";
import Like from "../assets/emoji_thumbs.svg";
import { EmojiReactions } from "../Reactions/types";
import Confused from "../assets/emoji_monocle.svg";
import { selectIsRemojiEnabled } from "../../event/selectors";

interface IEmojiType {
  name: EmojiReactions;
  imageType: string;
}

const emojiTypes = [
  { name: EmojiReactions.CLAP, imageType: Clap },
  { name: EmojiReactions.LIKE, imageType: Like },
  { name: EmojiReactions.CONFUSED, imageType: Confused },
];

const EmojiButtonContainer = () => {
  const isRemojiEnabled = useSelector(selectIsRemojiEnabled);

  if (!isRemojiEnabled) {
    return null;
  }

  return (
    <Container
      flex
      justifyContent="space-between"
      data-testid="emoji-button-container"
    >
      {emojiTypes.map((emoji: IEmojiType) => (
        <ReactedEmoji
          key={emoji.name}
          type={emoji.name}
          imageType={emoji.imageType}
          animationData={getAnimationData(emoji.name)}
        />
      ))}
    </Container>
  );
};

export default EmojiButtonContainer;
