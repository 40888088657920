import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles((theme) => ({
  container: {
    paddingBottom: "0px",
    width: theme.spacing(6),
    height: theme.spacing(6),
    position: "relative",
    marginLeft: theme.spacing(1.25),
    marginRight: theme.spacing(1.25),
  },
  emojiButton: {
    borderRadius: "50%",
    width: theme.spacing(6),
    height: theme.spacing(6),
    cursor: "pointer",
    border: "1px solid transparent",
    marginRight: "20px",
    transition: "transform ease 0.2s",
    position: "relative",
    marginTop: "-2px",
    userSelect: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0px",

    "&:hover,&:focus": {
      border: "1px solid #FA8D29",
      transform: "scale(1.2)",
      outline: "none",
    },
  },
  emojiImage: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    padding: "10px",
    objectFit: "contain",
  },
  animation: {
    position: "absolute",
    left: "0",
    top: "0",
    right: "0",
    bottom: "0",
    opacity: "0.8",
  },
}));
