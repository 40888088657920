import { Timestamp } from "firebase/firestore";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { EmojiReactions } from "../Reactions/types";

export interface ConversationEmoji {
  id: string;
  reactionType: EmojiReactions;
  time: Timestamp;
  userId: string;
}

export interface IEmojiState {
  conversation: Record<string, ConversationEmoji>;
}

export const initialState: IEmojiState = {
  conversation: {},
};

const emojiSlice = createSlice({
  name: "emoji",
  initialState,
  reducers: {
    addConversationEmoji: (
      state: IEmojiState,
      action: PayloadAction<ConversationEmoji>,
    ) => {
      state.conversation = {
        ...state.conversation,
        [action.payload.userId]: action.payload,
      };
    },
    clearEmojis: (state: IEmojiState) => {
      state.conversation = {};
    },
  },
});

export const { addConversationEmoji, clearEmojis } = emojiSlice.actions;

export default emojiSlice.reducer;
