export const REACTIONS_CONTAINER_ID = "reactions-container";

export const FLOATING_REACTION_IMAGE_SIZE = 18;

export const MAX_SIMULTANEOUS_FLOATING_REACTIONS = 20;

export const FLOATING_REACTION_DURATION = 3800;

export const FLOATING_REACTION_INTERVAL =
  FLOATING_REACTION_DURATION / MAX_SIMULTANEOUS_FLOATING_REACTIONS;

export const QUERY_CUTOFF_TIME = 5000;

export const REACTION_ANIMATION_EXPIRATION = 5000;
