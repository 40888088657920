import { useEffect, useState } from "react";
import { ConversationEmoji as ConversationEmojiType } from "modules/emoji/redux";
import useSystemClock from "modules/system/hooks/useSystemClock";
import { REACTION_ANIMATION_EXPIRATION } from "modules/emoji/Reactions/constants";

interface Props {
  emoji?: ConversationEmojiType;
}

export const useShowReaction = ({ emoji }: Props) => {
  const { getCurrentTime } = useSystemClock();
  const [showReaction, setShowReaction] = useState(false);

  useEffect(() => {
    if (!emoji) {
      setShowReaction(false);
      return;
    }

    const timer =
      new Date(
        emoji.time.seconds * 1000 +
          REACTION_ANIMATION_EXPIRATION +
          emoji.time.nanoseconds / 1000000,
      ).valueOf() - getCurrentTime();
    if (timer <= 0) {
      return;
    }

    setShowReaction(true);
    const timeoutId = window.setTimeout(() => {
      setShowReaction(false);
    }, timer);

    // eslint-disable-next-line consistent-return
    return () => {
      window.clearTimeout(timeoutId);
    };
  }, [emoji, getCurrentTime]);

  return {
    showReaction,
  };
};
