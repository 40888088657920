import { selectUserId } from "modules/auth/redux/selectors";
import { useSelector } from "react-redux";
import { playFloatingReaction } from "./playFloatingReaction";
import { createDelayingQueue } from "../createDelayingQueue";
import {
  FLOATING_REACTION_INTERVAL,
  MAX_SIMULTANEOUS_FLOATING_REACTIONS,
} from "../constants";
import { IEmojiReactionPayload } from "../types";

export const floatingReactionsQueue = createDelayingQueue(
  MAX_SIMULTANEOUS_FLOATING_REACTIONS,
  FLOATING_REACTION_INTERVAL,
);

export const useAddReaction = () => {
  const currentUserId = useSelector(selectUserId);
  const scheduleAddReaction = (reaction: IEmojiReactionPayload) => {
    if (!document.hasFocus()) return;

    const { reactionType, userId } = reaction;

    if (userId === currentUserId) {
      playFloatingReaction(reactionType);
    } else {
      floatingReactionsQueue.enqueue(() => playFloatingReaction(reactionType));
    }
  };
  return { scheduleAddReaction };
};
