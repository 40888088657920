import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles((theme) => ({
  tileEmojiImg: {
    position: "absolute",
    zIndex: 2,
    display: "flex",
    height: "54%",
    alignItems: "center",
    background: "transparent",
    border: "none",
    marginLeft: "28%",
    marginTop: "23%",
  },
  avatarEmojiImg: {
    zIndex: 2,
    width: theme.spacing(5),
    height: theme.spacing(5),
    objectFit: "contain",
    border: "none",
    background: "transparent",
  },
}));
