import { Container } from "@remo-co/ui-core/src/components/Container";
import { emojiTypes } from "modules/emoji/Reactions/EmojiReaction";
import { ConversationEmoji } from "../ConversationEmoji";

export const ConversationEmojiContainer = (): JSX.Element => (
  <Container
    flex
    justifyContent="space-between"
    data-testid="emoji-button-container"
  >
    {Object.keys(emojiTypes).map((name) => (
      <ConversationEmoji key={name} type={name} />
    ))}
  </Container>
);
