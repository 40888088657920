import ConfusedAnimation from "../assets/confused.json";
import ClapAnimation from "../assets/clap.json";
import LikeAnimation from "../assets/like.json";
import CountDownAnimation from "../assets/countdown.json";
import { EmojiReactions } from "./types";

export const getAnimationData = (reactionType: EmojiReactions) => {
  switch (reactionType) {
    case EmojiReactions.CLAP:
      return ClapAnimation;
    case EmojiReactions.CONFUSED:
      return ConfusedAnimation;
    case EmojiReactions.LIKE:
      return LikeAnimation;
    default:
      return CountDownAnimation;
  }
};
