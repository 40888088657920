import { Theme } from "@remo-co/ui-core/src/types";
import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles<Theme>(() => ({
  buttonContainer: {
    paddingBottom: "0px",
    width: "46px",
    height: "46px",
    position: "relative",
    marginRight: "20px",
  },
  emojiButton: {
    backgroundColor: "var(--EbonyClay2)",
    borderRadius: "50%",
    height: "46px",
    width: "46px",
    cursor: "pointer",
    border: "1px solid transparent",
    marginRight: "20px",
    transition: "transform ease 0.2s",
    position: "relative",
    marginTop: "-2px",
    userSelect: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0px",

    "&:hover,&:focus": {
      backgroundColor: "#efefef26",
      border: "1px solid #6482fc",
      color: "var(--White-op-090)",
      transform: "scale(1.3)",
      outline: "none",
    },
  },
  emojiImage: {
    width: "25px",
    height: "25px",
    padding: "10px",
    objectFit: "contain",
  },
  animation: {
    position: "absolute",
    left: "0",
    top: "0",
    right: "0",
    bottom: "0",
    opacity: "0.8; ",
  },
}));
