import React from "react";
import { ReactionEffects } from "modules/emoji/Reactions/hooks/useEmojiReactions";
import { selectRightPanelState, TABS } from "modules/rightPanel";
import { useSelector } from "react-redux";
import { selectIsRemojiEnabled } from "modules/event/selectors";
import { REACTIONS_CONTAINER_ID } from "./constants";
import "./EmojiReactionsContainer.scss";

const EmojiReactionsContainer = () => {
  const isRemojiEnabled = useSelector(selectIsRemojiEnabled);
  const { tabId } = useSelector(selectRightPanelState);
  const classNames = ["emoji-reaction-container"];

  if (!isRemojiEnabled) return null;

  if (tabId === TABS.CHAT || tabId === TABS.QA) {
    classNames.push("bottom-offset");
  }

  return (
    <>
      <div id={REACTIONS_CONTAINER_ID} className={classNames.join(" ")} />
      <ReactionEffects />
    </>
  );
};

export default React.memo(EmojiReactionsContainer);
