export type Task = () => void;

export function createDelayingQueue(
  maxTasks: number,
  delayBetweenTaskExecution: number,
) {
  let queue: Task[] = [];
  let isExecuting = false;

  function enqueue(task: Task) {
    if (queue.length === maxTasks) return;

    queue.push(task);

    if (!isExecuting) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      execute();
    }
  }

  async function execute() {
    isExecuting = true;

    if (queue.length) {
      const [task] = queue;

      task();

      setTimeout(() => {
        queue.shift();
        execute();
      }, delayBetweenTaskExecution);
    } else {
      isExecuting = false;
    }
  }

  function stop() {
    if (queue.length) {
      queue = [];
    }
  }

  return { enqueue, stop };
}
